<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>代理商管理</p>
			<i>/</i>
			<p @click="jumpPage">代理商列表</p>
			<i>/</i>
			<p class="active">代理商续费</p>
		</nav>
	
		<div class="page_edit">
	
			<div class="page_edit_form">
				<h4 class="title">基础信息</h4>
				<div class="enterprise_name">
					<p>代理商名称：</p>
					<p>{{$route.query.name}}</p>
				</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="" prop="agent_role_id">
						<p class="form_label_p">代理商角色</p>
						<el-select class="input_long" v-model="ruleForm.agent_role_id" filterable placeholder="请选择">
							<el-option v-for="item in roleLidt" :key="item.id"
								:label="item.role_name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="" prop="">
						<p class="form_label_p">时长</p>
						<el-radio-group v-model="ruleForm.productYear">
							<el-radio label="1">1年</el-radio>
							<el-radio label="2">2年</el-radio>
							<el-radio label="3">3年</el-radio>
							<el-radio label="4">其它</el-radio>
						</el-radio-group>
						<el-input v-if="ruleForm.productYear == '4'" class="input_mini input_mini_radio" v-model="ruleForm.productYear1"
							placeholder="请输入正整数"></el-input>
						<span class="span1">年</span>
					</el-form-item>
					<div  class="el-form-item_last"></div>
					<el-form-item class="el-form-item-preservation">
						<div class="page_edit_preservation">
							<p class="p1" @click="upPage">返回</p>
							<p class="p2" v-if="modifyBtn" @click="createForm('ruleForm')">保存</p>
							<p class="p2" v-else @click="editForm('ruleForm')">保存</p>
						</div>
					</el-form-item>
				</el-form>
			</div>
	
		</div>
	
	</div>
</template>

<script>
	
	export default {
		name: 'agenRenewEdit',
		data() {
			return {
				ruleForm: {
					agent_role_id: '',
					productYear: '1',
					productYear1: '', //时长其它
				},
				rules: {
					agent_role_id: [{
						required: true,
						message: '代理商角色不能为空',
						trigger: 'change'
					}],
					productYear1: [
						{
							required: true,
							message: '时长不能为空',
							trigger: 'blur'
						}
					]
				},
				roleLidt: [],  // 代理商角色
				modifyBtn: true, // 默认创建
				infoID: '',  // 详情ID，编辑的时候用到
			}
		},
		components: {
			
		},
		created() {
			
			// 获取代理商角色
			this.getRoleList();
			
			// 获取详情做修改
			this.getInfo();
			
		},
		methods: {
			// 获取代理商角色
			getRoleList(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}agentrole/agentrolelist`,
					data: {}
				}).then( res => {
					if(res.code == '0'){
						this.roleLidt = res.data;
					}
				})
			},
			
			// 获取详情做修改
			getInfo(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}agentreneworder/detail`,
					data: {
						id: this.$route.query.agent_id
					}
				}).then( res => {
					if(res.code == '0'){
						if(res.data.agent_id){
							this.modifyBtn = false;
						}
						
						this.ruleForm.agent_role_id = res.data.agent_role_id;
						this.infoID = res.data.id;
						
						if(res.data.useful_life < 4){
							this.ruleForm.productYear = res.data.useful_life;
						}
						else{
							this.ruleForm.productYear = '4',
							this.ruleForm.productYear1 = res.data.useful_life;
						}
						
					}
				})
			},

			// 创建
			createForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let time1 = '';
						if(this.ruleForm.productYear < '4'){
							time1 = this.ruleForm.productYear;
						}
						else{
							time1 = this.ruleForm.productYear1;
						}
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}agentreneworder/add`,
							data: {
								agent_id: this.$route.query.agent_id,
								agent_role_id: this.ruleForm.agent_role_id,
								useful_life: time1,
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '创建成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 修改
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let time1 = '';
						if(this.ruleForm.productYear < 4){
							time1 = this.ruleForm.productYear;
						}
						else{
							time1 = this.ruleForm.productYear1;
						}
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}agentreneworder/edit`,
							data: {
								agent_id: this.$route.query.agent_id,
								agent_role_id: this.ruleForm.agent_role_id,
								useful_life: time1,
								id: this.infoID,
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '编辑成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},
			
			// 头部跳转页面
			jumpPage(){
				this.$router.go(-1);
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}
	
	.title{
		padding-left: 20px;
		background-color: #fff;
		height: 60px;
		line-height: 60px;
		font-size: 14px;
		color: #000;
		font-weight: bold;
	}
	
	.enterprise_name{
		background-color: #fff;
		display: flex;
		justify-content: flex-start;
		padding-left: 20px;
		p{
			font-size: 14px;
			color: #000;
			height: 44px;
			line-height: 44px;
		}
	}

</style>
